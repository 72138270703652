///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Feature Icons */

	ul.feature-icons {
		@include vendor('display','flex');
		@include vendor('align-items','stretch');
		@include vendor('justify-content','center');
		@include vendor('flex-wrap','wrap');
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			padding: 0 _size(element-margin) * 1.25;
			text-align: center;
			margin-bottom: _size(element-margin);

			a {
				text-decoration: none;
				font-weight: _font(weight-bold);
				font-size: 1.25rem;
			}

			.icon {
				border-radius: 100%;
				border: 2px solid;
				display: block;
				height: 6rem;
				line-height: 6rem;
				margin: 0 auto (_size(element-margin) / 2 ) auto;
				text-align: center;
				width: 6rem;

				&:before {
					font-size: 2rem;
				}
			}
		}

		@include breakpoint('<=small') {
			li {
				margin-bottom: _size(element-margin) / 2.5;
				padding: 0 _size(element-margin);

				.icon {
					height: 4.5rem;
					line-height: 4.5rem;
					margin: 0 auto (_size(element-margin) / 2 ) auto;
					width: 4.5rem;

					&:before {
						font-size: 1.5rem;
					}
				}

				.label {
					display: none;
				}
			}
		}

		@include breakpoint('<=xsmall') {
			li {
				padding: 0 _size(element-margin) / 2.5;
				.icon {
					height: 4rem;
					line-height: 4rem;
					width: 4rem;

					&:before {
						font-size: 1.5rem;
					}
				}

				.label {
					display: none;
				}
			}
		}
	}

	@mixin color-feature-icons($p: null) {

		ul.color-feature-icons {
			li {
				color: _palette($p, fg);

				.icon {
					color: _palette($p, fg-bold);
					border-color: _palette($p, fg-bold);
				}
			}
		}
	}

	@include color-feature-icons;