///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

	.image {
		border-radius: _size(border-radius);
		border: 0;
		display: inline-block;
		position: relative;

		img {
			border-radius: _size(border-radius);
			display: block;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 2rem 2rem 0;
			top: 0.25rem;
		}

		&.right {
			float: right;
			margin: 0 0 2rem 2rem;
			top: 0.25rem;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.special {
			box-shadow: 0px 15px 98px -20px rgba(0,0,0,0.35);
			margin: (_size(element-margin) * 2) auto (_size(element-margin) * 2.5) auto;
			max-width: 75%;

			@include breakpoint('<=large') {
				max-width: 100%;
			}

			@include breakpoint('<=medium') {
				margin: (_size(element-margin) * 1.5) 0 (_size(element-margin) * 2) 0;

				img {
					height: 18rem;
					object-fit: cover;
					object-position: center center;
				}
			}

			@include breakpoint('<=small') {
				margin: (_size(element-margin) * 1) 0 (_size(element-margin) * 1.5) 0;

				img {
					height: 12rem;
				}
			}

			@include breakpoint('<=xsmall') {
				img {
					height: 10rem;
				}
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}

			@include breakpoint('<=small') {
				margin: 0 0 _size(element-margin) 0;
			}
		}
	}