///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'libs/fixed-grid';
@import 'font-awesome.min.scss';
@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i');

// Breakpoints.

	@include breakpoints((
		default:   (1681px,   null     ),
		xlarge:    (1281px,   1680px   ),
		large:     (981px,    1280px   ),
		medium:    (737px,    980px    ),
		small:     (481px,    736px    ),
		xsmall:    (361px,    480px    ),
		xxsmall:   (null,     360px    )
	));

// Color.

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-table($p);
		@include color-feature-icons($p);
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/feature-icons';
	@import 'components/icons';
	@import 'components/table';
	@import 'components/wrapper';
	@import 'components/inner';
	@import 'components/spotlights';
	@import 'components/profiles';

// Layout.

	@import 'layout/header';
	@import 'layout/navPanel';
	@import 'layout/banner';
	@import 'layout/footer';

// Custom.

	@import 'custom';

/**/
/* GALLERY */
/**/

.gallery{
  width: 100%;
  display: block;
  margin:0 auto;
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  .gallery {
    column-count: 1;
    column-gap: 3px;
  }
}
/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .gallery {
    column-count: 2;
    column-gap: 3px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .gallery { /* Masonry container */
      column-count: 3;
      column-gap: 3px;
  }
}

/* Large Devices, Wide Screens */ 
@media only screen and (min-width : 1200px) {
  .gallery{
    column-count: 3;
    column-gap: 0.2rem;
    width: 100%;
  }
}


.gallery-item { /* Masonry bricks or child elements */
    background-color: #eee;
    display: inline-block;
    margin: 0 0 0.2rem;
    width: auto;
}