.wrapper {
	@include padding(6rem, 0);

	&.home_bg {
		background: url('/images/home_bg.jpg');
	}
}

.float-right{
	float:right;
	display:block;
}

.float-left{
	float:left;
}
.apoios{
	height:150px;
	width:100%;
	margin: 3em 0 3em 0;
}

/*COLUNAS*/

.flexbox {
        display: flex;
        flex-wrap: wrap;
}

// .box>* {
//     flex: 1 1 160px;
// }

.flexbox > .element{
	flex: 1 1 160px;	
}